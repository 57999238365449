import React from 'react';

const Main = () => {
  return (
    <>
      <section className="fmc-ma-5">
        <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Thanks</h1>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Your purchase is complete.</strong>
        </p>
        <p className="fds-color__text--tertiary">We look forward to seeing you at Ford Towing Bootcamp!</p>
      </section>
    </>
  );
};

export default Main;
