import React from 'react';

const Main = () => {
  return (
    <>
      <section className="fmc-ma-5">
        <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
          Frequently Asked Questions
        </h1>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Will I be using my own vehicle during the event?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          No, your registration includes the use of a Ford F-150 vehicle during the driving experience.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>What are the requirements to make a reservation?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          To make a reservation you must be 18 years of age or older, have a valid driver's license, and currently own a
          Ford SUV or Truck.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>What does the experience include?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          You will receive 3 hours of expertly led conventional towing instruction designed to build your confidence and
          develop your skills behind the wheel. You'll start off with a Towing 101 course covering the towing concepts
          you need to know before getting behind the wheel. From there, you will hitch-up on a closed course for behind
          the wheel guidance from your in-vehicle instructor. After your scheduled training is complete, interactive
          learning stations will be available at your convenience, providing opportunities to further develop your
          understanding of towing skills, tools, and concepts. Snacks to refuel will be provided, and meals will be
          available for purchase on site.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>I've never towed a trailer before. Is that okay?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Absolutely! Whether you're considering your first trailer-towing adventure or have towed before and are
          looking for additional training, our Ford Towing Bootcamp is designed to boost your confidence and build your
          towing knowledge and skills. You will be participating with other attendees with a variety of experience
          levels.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Will an instructor be in the vehicle with me?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Our team of expert towing instructors will lead your Bootcamp experience throughout the day. For all driving
          experiences, one (1) instructor will be riding in the vehicle with up to two (2) driving participants,
          providing continual communication, and offering towing tips, tricks, and techniques.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>How long should I plan for my experience?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Your registration time includes 3 hours of scheduled towing instruction. If your schedule permits, reserve
          additional time after your scheduled training to explore our interactive learning stations. Please be sure to
          arrive 15 minutes prior to your reservation time for check-in.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>How much does it cost to attend?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          The 3-hour training experience costs $495 for a single participant. This includes expert instruction from
          licensed trainers, use of a Ford F-150 on a closed driving course, and snacks to refuel.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Can I bring a guest?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Of course! You can add two (2) driving guests for $395 per person. Driving guests receive the same curriculum
          and behind the wheel training as reservation holders. All driving guests must be 18 years of age or older and
          present a valid driver's license at event check-in.
        </p>

        <p className="fds-color__text--tertiary">
          You may also add one (1) non-driving guest for every driving participant in your reservation party.
          Non-driving guests 18 years of age or older can attend for $25. Non-Driving guests 10-17 years of age can
          attend at no added cost.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>How old does someone have to be to attend as a guest?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          This is a family-friendly event, and children 10 years of age or older are welcome! To get behind the wheel,
          all driving guests must be 18 years of age or older and present a valid driver's license at event check-in.
          All adults and children must be properly restrained with an individual seatbelt or appropriately sized child
          restraint and per NHTSA guidelines (restraint not supplied at event and must be brought by reservation
          holder).
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Can I bring my pet with me?</strong>
        </p>
        <p className="fds-color__text--tertiary">Sorry, no pets allowed (other than service animals).</p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>What should I bring with me?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Prepare for a 3-hour hands-on towing experience! We recommend bringing the following:
        </p>
        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <li>Your reservation confirmation email (digital or printed copy)</li>
          <li>Valid driver's license for anyone planning to drive onsite</li>
          <li>Sunglasses</li>
          <li>Sunscreen</li>
          <li>Any medication you may need</li>
          <li>Comfortable clothing appropriate for the weather</li>
          <li>
            Closed-toe shoes that provide comfort and control while driving, such as thin-soled boots, sneakers, or
            running shoes are recommended.
          </li>
          <li>Plan for unexpected weather changes with a jacket, hat, and/or rain shell</li>
        </ul>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Please note that alcohol is not permitted onsite.</strong>
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>What if I want to reschedule my Ford Towing Bootcamp reservation?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          To change your Bootcamp reservation, call or email our team at
          <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a> or (888) 367-3192. You may
          reschedule your experience 72 hours or more before your experience for a $50 rescheduling fee, subject to
          availability at another event.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>What if I need to cancel my Ford Towing Bootcamp reservation?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          We hope this won't be the case! In the event you need to cancel your reservation, the following terms apply:
        </p>
        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <li>
            72 hours or more before your experience - you will receive a full refund minus a $50 processing charge
          </li>
          <li>Less than 72 hours before your experience - no refunds</li>
        </ul>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Is the Ford Towing Clinic ADA accessible?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          The Ford Towing Bootcamp is held at the North Carolina State Fairgrounds and Texas Motor Speedway and they are
          ADA accessible. For specific requirements or request, please contact (888)-367-3192 or email{' '}
          <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a>.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Will the event be accessible by wheelchair or power scooter?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          The venue is accessible by wheelchair or power scooter. To participate in the Ford Towing Bootcamp driving
          portion, you will need to be able to safely operate a motor vehicle.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Will there be accessible parking spaces?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          Yes, accessible parking will be available near the main entrance of the facility.
        </p>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Help! What if I need further assistance?</strong>
        </p>
        <p className="fds-color__text--tertiary">
          If you've hit a roadblock anywhere in the registration process, or if you need any additional help, please
          email our team at <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a> or (888)
          367-3192. A Ford Towing Bootcamp Specialist will be happy to get you back on track.
        </p>
      </section>
    </>
  );
};

export default Main;
