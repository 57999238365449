/// <reference types="@types/google.maps" />

import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { FunctionFactory, SurveyModel, UpdateQuestionCssClassesEvent } from 'survey-core';
import { Survey } from 'survey-react-ui';
import showdown from 'showdown';

import * as Sentry from '@sentry/react';

import 'survey-core/defaultV2.min.css';
import '@/assets/css/survey-override.css';

import { themeJSON } from '@/assets/survey/FordTheme';
import baseSurvey from './PreSurvey.json';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Attendee } from '@/types/attendee';

function validateEmail(this: any, [questionName]: string[]) {
  console.log('[validateEmail]', arguments);
  if (!questionName) {
    this.returnResult(true);
    return;
  }

  const email = this.survey.getValue(questionName);

  if (!email) {
    this.returnResult(true);
    return;
  }

  fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}validateEmail-e2jtv773lq-uc.a.run.app`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  })
    .then(response => {
      response.json().then(res => {
        const { results } = res;

        let valid = true;

        // bad emails are rejected
        if (results?.valid === false) {
          valid = false;
        }

        // disposable email services are rejected
        if (results?.is_disposable === true) {
          valid = false;
        }

        // reject delivery_confidence below 20
        if (results?.delivery_confidence < 20) {
          valid = false;
        }

        // typos are rejected with correction
        if (results.did_you_mean) {
          valid = false;
        }

        if (!valid) {
          this.question.errors = [];
        }

        this.returnResult(valid);
      });
    })
    .catch(err => {
      alert(err);
    });
}

FunctionFactory.Instance.register('validateEmail', validateEmail, true);

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');

const customCSS = {
  // bodyNavigationButton: 'fmc-button',
  // sd-text fmc-input
  text: {
    root: 'fmc-input',
    onError: 'fmc-input fmc-error',
  },
  checkbox: {
    root: 'fmc-check',
  },
  radiogroup: {
    root: 'fmc-check',
    onError: 'fmc-input fmc-error',
  },
  completedBeforePage: '',
};

baseSurvey.title = 'Ford Towing Bootcamp Waiver';
const surveyModel = new Model(baseSurvey);
surveyModel.applyTheme(themeJSON);
surveyModel.css = customCSS;

const PreSurvey = () => {
  const [searchParams] = useSearchParams();
  const attendeeID = searchParams.get('attendeeID');

  const [attendee, setAttendee] = useState<Attendee>();
  const [attendeeLoading, setAttendeeLoading] = useState(true);

  useEffect(() => {
    if (attendeeID) {
      const fetchAttendee = async () => {
        try {
          const response = await fetch(
            `https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getAttendee-e2jtv773lq-uc.a.run.app`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ attendeeID }),
            },
          );

          if (!response.ok) {
            console.error('Error fetching attendee:', response);
            setAttendeeLoading(false);
            throw new Error('Failed to fetch attendee');
          }

          const attendeeData = await response.json();
          console.log('attendeeData', attendeeData);

          surveyModel.title = `${baseSurvey.title} - ${attendeeData.eventTime}`;

          surveyModel.mergeData({
            surveyType: 'preTD',
            attendeeID,
            eventID: attendeeData.eventID,
            cartID: attendeeData.cartID,
            firstName: attendeeData.firstName,
            lastName: attendeeData.lastName,
            email: attendeeData.email,
            phone: attendeeData.phone,
            hasMinor: attendeeData.hasMinor,
            minorName1: attendeeData.hasMinor
              ? `${attendeeData.minorFirstName} ${attendeeData.minorLastName}`
              : undefined,
          });

          setAttendee(attendeeData as Attendee);
          setAttendeeLoading(false);
        } catch (error) {
          console.error('Error fetching attendee:', error);
          setAttendeeLoading(false);
          throw error;
        }
      };

      fetchAttendee();
    }
  }, [attendeeID]);

  useEffect(() => {
    surveyModel.onUpdateQuestionCssClasses.add((sender: SurveyModel, options: UpdateQuestionCssClassesEvent) => {
      if (options.question.name === 'signature') {
        options.cssClasses.root += ' signatureInput';
      }
      if (options.question.name === 'minor_signature') {
        options.cssClasses.root += ' signatureInput';
      }
    });

    var converter = new showdown.Converter({
      openLinksInNewWindow: true,
    });
    surveyModel.onTextMarkdown.add(function (sender, options) {
      //convert the mardown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });

    surveyModel.onAfterRenderQuestionInput.add((sender, options) => {
      if (options.question.name === 'address1') {
        const autocomplete = new google.maps.places.Autocomplete(options.htmlElement as HTMLInputElement, {
          types: ['address'],
          componentRestrictions: {
            country: ['us'],
          },
          fields: ['address_components', 'formatted_address'],
          ...options.question.addressAutocompleteConfig,
        });

        autocomplete.addListener('place_changed', async function () {
          const place = await autocomplete.getPlace();

          const ParsedData: { [key: string]: string | undefined } = {
            formatted_address: place.formatted_address,
          };

          const postalData = place.address_components?.find(item => item.types.includes('postal_code'));
          const countryData = place.address_components?.find(item => item.types.includes('country'));
          const addressData = place.address_components?.find(item =>
            item.types.includes('administrative_area_level_1'),
          );
          const cityData = place.address_components?.find(item => item.types.includes('locality'));
          const routeData = place.address_components?.find(item => item.types.includes('route'));
          const streetNumberData = place.address_components?.find(item => item.types.includes('street_number'));

          ParsedData.address1 = [streetNumberData?.long_name, routeData?.long_name].join(' ').trim();
          ParsedData.city = cityData == null ? '' : cityData.long_name;
          ParsedData.state = addressData == null ? '' : addressData.short_name;
          ParsedData.zip = postalData == null ? '' : postalData.long_name;
          ParsedData.country = countryData == null ? '' : countryData.short_name;

          ['address1', 'city', 'state', 'zip', 'country'].forEach(key => {
            try {
              surveyModel.setValue(key, ParsedData[key], true, true);
            } catch (e) {
              console.log('error', e);
            }
          });
        });
      }
    });

    surveyModel.onComplete.add((sender, options) => {
      sender.setValue('surveyDate', new Date());

      console.log('onComplete', sender.data);

      const data = {
        ...sender.data,
      };

      // logEvent(analytics, 'begin_checkout', {
      //   currency: 'USD',
      //   value: sender.getValue('priceTotal'), // Total Revenue
      // });

      // save data to firestore carts collection
      fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveSurvey-e2jtv773lq-uc.a.run.app`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          survey: data,
        }),
      })
        .then(() => {
          options.showDataSavingSuccess();
          const redirect = searchParams.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err, {
            tags: {
              page: 'PreDrive',
              location: 'surveyOnComplete',
              type: 'Survey Save Error',
            },
          });
        });
    });
  }, []);

  return (
    <>
      {attendee ? (
        attendee.waiverComplete ? (
          <>
            <div className="fds-layout-grid__inner">
              <div className="fds-layout-grid__cell--span-12">
                <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                  <div className="fmc-billboard__image event_billboard_background"></div>
                </div>
              </div>
            </div>
            <section className="fmc-ma-5">
              <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
                Waiver Already Completed
              </h1>
            </section>
          </>
        ) : (
          <Survey model={surveyModel} />
        )
      ) : !attendeeLoading ? (
        <>
          <div className="fds-layout-grid__inner">
            <div className="fds-layout-grid__cell--span-12">
              <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                <div className="fmc-billboard__image event_billboard_background"></div>
              </div>
            </div>
          </div>
          <section className="fmc-ma-5">
            <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Invalid ID</h1>
          </section>
        </>
      ) : (
        <div>Loading Waiver...</div>
      )}
    </>
  );
};

export default PreSurvey;
