import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '@/services/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';
import db from '@/services/db';
import * as Sentry from '@sentry/react';
import {
  CollectionReference,
  Query,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  collection,
  query,
  where,
  orderBy,
  updateDoc,
  Timestamp,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

import JSONPretty from 'react-json-pretty';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import QRCode from 'qrcode.react';

import _ from 'lodash';
import moment from 'moment-timezone';

type CheckAttendee = {
  id: string;
  ref: DocumentReference<DocumentData>;
  surveyDate: Date;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  eventTime: string;
  eventDate?: Date;
  waiverComplete: boolean;
  otherAnswers: any;
  _checkedIn: Date;
  _checkedOut: Date;
  _claimed: Date;
  _used: Date;
};

const checkUserConverter: FirestoreDataConverter<CheckAttendee> = {
  toFirestore(checkAttendee: CheckAttendee): DocumentData {
    return {
      firstName: checkAttendee.firstName,
      lastName: checkAttendee.lastName,
      email: checkAttendee.email,
      phone: checkAttendee.phone,
      waiverComplete: checkAttendee.waiverComplete,
      eventTime: checkAttendee.eventTime,
      eventDate: checkAttendee.eventDate,
      _checkedIn: checkAttendee._checkedIn ? Timestamp.fromDate(checkAttendee._checkedIn) : null,
      _checkedOut: checkAttendee._checkedOut ? Timestamp.fromDate(checkAttendee._checkedOut) : null,
      _claimed: checkAttendee._claimed ? Timestamp.fromDate(checkAttendee._claimed) : null,
      _used: checkAttendee._used ? Timestamp.fromDate(checkAttendee._used) : null,
    };
  },
  fromFirestore(snapshot, options): CheckAttendee {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      ref: snapshot.ref,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      waiverComplete: data.waiverComplete,
      surveyDate: new Date(data.surveyDate),
      eventTime: data.eventTime,
      eventDate: data.eventDate ? new Date(data.eventDate) : undefined,
      _checkedIn: data._checkedIn?.toDate(),
      _checkedOut: data._checkedOut?.toDate(),
      _claimed: data._claimed?.toDate(),
      _used: data._used?.toDate(),
      otherAnswers: _.omit(data, [
        'surveyDate',
        'firstName',
        'lastName',
        'email',
        'phone',
        'waiverComplete',
        'eventTime',
        'eventDate',
        '_checkedIn',
        '_checkedOut',
        '_claimed',
        '_used',
        'start_time',
        'device_id',
        'event_id',
        'app_version',
        'abandoned',
      ]),
    };
  },
};

const eventID = 'raleigh';

const checkInDisplay = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  waiverComplete: 'Waiver Complete',
};

function CheckInScreen() {
  const navigate = useNavigate();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [checkInMode, setCheckInMode] = useState(true);
  const [attendeesRef, setAttendeesRef] = useState<CollectionReference>();
  const [attendeesQuery, setAttendeesQuery] = useState<Query>();
  const [selectedAttendee, setSelectedAttendee] = useState<QueryDocumentSnapshot<CheckAttendee>>();
  const [user, userLoading, userError] = useAuthState(auth);
  const [attendees, attendeesLoading, attendeesError] = useCollection(attendeesQuery);

  useEffect(() => {
    console.error(attendeesError);
  }, [attendeesError]);

  useEffect(() => {
    attendees?.docs.forEach(doc => {
      const attendee = doc.data();
      if (!attendee.eventDate) {
        updateDoc(doc.ref, {
          eventDate: moment(attendee.eventTime, 'dddd MMM DD, hh:mm a').tz('America/Chicago').toDate(),
        });
      }
    });
  }, [attendees]);

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  useEffect(() => {
    console.log('attendeesLoading', attendeesLoading);
  }, [attendeesLoading]);

  useEffect(() => {
    if (userLoading) return;

    // see if the user is logged in
    console.log('user', user);

    if (!user) {
      navigate('./login');
    }

    setAttendeesRef(collection(db, 'attendees'));
  }, [userLoading]);

  useEffect(() => {
    if (!userLoading && attendeesRef) {
      setAttendeesQuery(
        query(
          attendeesRef,
          where('eventID', '==', eventID),
          where('_checkedIn', checkInMode ? '==' : '!=', null),
          where('_checkedOut', '==', null),
          orderBy('eventDate'),
          orderBy('lastName'),
          orderBy('firstName'),
        ).withConverter(checkUserConverter),
      );
    }
  }, [userLoading, checkInMode, attendeesRef]);

  const CheckInRenderer = (props: ListViewItemProps) => {
    const doc = props.dataItem as QueryDocumentSnapshot<CheckAttendee>;
    const attendee: CheckAttendee = doc.data();

    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{ margin: 0, padding: '0.5em', display: 'flex', backgroundColor: props.index! % 2 ? '#eee' : '#fff' }}
      >
        <div style={{ flex: 4 }}>
          <h2 style={{ fontSize: 14, color: '#454545', marginBottom: 0 }} className="text-uppercase">
            {attendee.firstName} {attendee.lastName}
          </h2>
          <div style={{ fontSize: 12, color: '#a0a0a0' }}>
            {attendee.email} {attendee.phone}{' '}
            {attendee._checkedIn && (
              <>
                Checked In: {attendee._checkedIn?.toLocaleDateString()} {attendee._checkedIn?.toLocaleTimeString()}
              </>
            )}
          </div>
        </div>
        <div style={{ flex: 2 }}>{attendee.eventTime}</div>
        <div style={{ flex: 1 }}>
          {checkInMode ? (
            <Button
              themeColor="primary"
              onClick={() => {
                setSelectedAttendee(doc);
                setDialogVisible(true);
              }}
            >
              Check In
            </Button>
          ) : attendee._checkedOut ? (
            <Button themeColor="primary" disabled>
              Already Checked Out
            </Button>
          ) : (
            <Button
              themeColor="primary"
              onClick={() => {
                updateDoc(attendee.ref, {
                  _checkedIn: null,
                })
                  .then(() => {
                    console.log('checked in');
                  })
                  .catch((err: any) => {
                    console.error('Check In Error', err);
                    Sentry.captureException(err);
                  });
              }}
            >
              Undo Check In
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <h1 className="text-center my-3 title">Check In {eventID.toLocaleUpperCase()}</h1>

      {userLoading && (
        <>
          <br />
          <br />
          <p>Loading...</p>
        </>
      )}

      {attendees && (
        <div style={{ marginTop: '3em' }}>
          <ButtonGroup>
            <Button themeColor={checkInMode ? 'primary' : 'base'} onClick={() => setCheckInMode(true)}>
              Check In
            </Button>
            <Button themeColor={!checkInMode ? 'primary' : 'base'} onClick={() => setCheckInMode(false)}>
              View Checked In
            </Button>
          </ButtonGroup>
          <ListView data={attendees.docs} item={CheckInRenderer} style={{ width: '100%', marginTop: '1em' }} />
        </div>
      )}
      {console.log('selectedAttendee', selectedAttendee?.data())}
      {dialogVisible && selectedAttendee && (
        <Dialog title="Check In Person?" onClose={() => setDialogVisible(false)}>
          {checkInDisplay ? (
            <ul>
              {Object.entries(checkInDisplay).map(([index, title]) => (
                <li key={index}>
                  <strong>{title as string}</strong>:{' '}
                  {index in selectedAttendee.data()
                    ? String(selectedAttendee.data()[index as keyof CheckAttendee])
                    : String(selectedAttendee.data().otherAnswers[index as keyof CheckAttendee]) || 'N/A'}
                </li>
              ))}
            </ul>
          ) : (
            <>
              <p>
                {selectedAttendee.data().firstName} {selectedAttendee.data().lastName}
              </p>
              <JSONPretty id="json-pretty" data={JSON.stringify(selectedAttendee.data().otherAnswers)}></JSONPretty>
            </>
          )}
          {!selectedAttendee.data().waiverComplete && (
            <div style={{ marginTop: '1em', textAlign: 'center' }}>
              <p>
                Waiver not completed.
                <br />
                Please scan the QR code to complete the waiver:
              </p>
              <QRCode
                value={`${window.location.origin}/pre?attendeeID=${selectedAttendee.id}`}
                size={256}
                style={{ marginTop: '1em' }}
              />
            </div>
          )}
          <br />
          <strong>id</strong>:{' ' + selectedAttendee.id}
          <DialogActionsBar>
            <Button
              themeColor="light"
              onClick={() => {
                setDialogVisible(false);
              }}
            >
              Cancel
            </Button>
            {selectedAttendee.data().waiverComplete ? (
              <Button
                themeColor="success"
                onClick={() => {
                  updateDoc(selectedAttendee.ref, {
                    _checkedIn: new Date(),
                  })
                    .then(() => {
                      console.log('checked in');
                    })
                    .catch((err: any) => {
                      console.error('Check In Error', err);
                      Sentry.captureException(err);
                    });
                  setDialogVisible(false);
                }}
              >
                Check In
              </Button>
            ) : (
              <Button
                themeColor="warning"
                onClick={() => {
                  navigate(
                    `/pre?attendeeID=${selectedAttendee.id}&redirect=${encodeURIComponent(
                      window.location.pathname + window.location.search,
                    )}`,
                  );
                  setDialogVisible(false);
                }}
              >
                Complete Waiver on iPad
              </Button>
            )}
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}

export default CheckInScreen;
