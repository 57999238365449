import React from 'react';
import { createComponent } from '@lit-labs/react';
import { analytics, logEvent } from '@/firebase';

import FMCPopoverClass from '@ford/ford-design-system/src/web-components/fmc-popover/src/FMCPopover.js';
import '@ford/ford-design-system/src/web-components/fmc-popover/fmc-popover.js';

import logo from '@/assets/images/logo/built-ford-tough.svg';
import logoPNG from '@/assets/images/logo/built-ford-tough.png';

import hitching from '@/assets/images/hitching.jpg';
import classroom from '@/assets/images/classroom.jpg';
import reversing from '@/assets/images/reversing.jpg';

import maurice from '@/assets/images/trainers/Maurice.jpg';
import darren_cindy from '@/assets/images/trainers/DarrenandCindy.jpg';
import carolina from '@/assets/images/trainers/Carolina.jpg';

const FMCPopover = createComponent({ react: React, tagName: 'fmc-popover', elementClass: FMCPopoverClass });

const Main = () => {
  let displayLogo = logo;

  return (
    <>
      <div className="fds-layout-grid__inner">
        <div className="fds-layout-grid__cell--span-12">
          <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
            <div className="fmc-billboard__image towing_billboard_background"></div>
            <div
              className="fmc-billboard__content fds-color__text--white"
              style={{ display: 'flex', width: '100%', margin: '10px' }}
            >
              <img
                className="towing_billboard_logo"
                src={displayLogo}
                onError={() => (displayLogo = logoPNG)}
                alt="Built Ford Tough"
              />
              <div style={{ display: 'flex', width: '60%' }}>
                <h1
                  className="fmc-type--heading4"
                  style={{
                    color: 'white',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  Learn the art of towing with Ford.
                </h1>
              </div>
            </div>
          </div>
          <span className="fmc-weight--light fmc-type--content2 fds-color__text--gray3">
            Towing capability varies based on cargo, vehicle configuration, accessories, and number and weight of
            passengers. Max towing often requires optional equipment at an extra charge. Doorjamb is the source of what
            an actual vehicle can tow.
          </span>
        </div>
      </div>
      <section className="fmc-ma-5 fmc-divider--bottom">
        <h2 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-3 fmc-mb-5-md">
          Build your towing skills at Ford Towing Bootcamp.
        </h2>

        <a href="#signup">
          <button className="fmc-button fmc-mb-6" aria-label="Sign Up Now">
            Sign Up Now
          </button>
        </a>

        <ul className="fds-color__text--tertiary fmc-list fmc-mb-3">
          <h3 className="fds-color__text--tertiary fmc-type--heading6">Why choose Ford Towing Bootcamp?</h3>
          <li>Four hours of instruction from certified Ford professional instructors</li>
          <li>Hands-on training with Ford's latest towing technology</li>
          <li>Safety protocols and best practices</li>
          <li>Ford F-150 and trailers are provided </li>
          <li>Complimentary refreshments</li>
          <li>Spanish speaking instruction available</li>
          <li>All women instructional groups available</li>
        </ul>

        <ul className="fds-color__text--tertiary fmc-list fmc-mb-3">
          <h3 className="fds-color__text--tertiary fmc-type--heading6">What we hope you'll gain:</h3>
          <li>Enhanced towing skills</li>
          <li>In-depth understanding of Ford towing capabilities</li>
          <li>Added towing safety techniques</li>
          <li>Increased confidence on the road</li>
        </ul>

        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <h3 className="fds-color__text--tertiary fmc-type--heading6">Cost:</h3>
          <li>Starts at $495</li>
        </ul>

        <h3 className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-3">
          The half-day Ford Towing Bootcamp experience will include:
        </h3>

        <div className="fmc-cards fds-layout-grid__inner fmc-mb-5">
          <div className="fmc-cards__card fds-layout-grid__cell">
            <div className="fmc-cards__media-area ">
              <img className="fmc-cards__image" src={classroom} alt="Classroom instruction" />
            </div>
            <div className="fmc-cards__content">
              <p className="fmc-cards__body">Classroom session lead by both men and women CDL Licensed instructors.</p>
            </div>
          </div>

          <div className="fmc-cards__card fds-layout-grid__cell">
            <div className="fmc-cards__media-area ">
              <img className="fmc-cards__image" src={hitching} alt="Hitching practice" />
            </div>
            <div className="fmc-cards__content">
              <p className="fmc-cards__body">
                Hitching and unhitching practice in a low pressure learning environment.
              </p>
            </div>
          </div>

          <div className="fmc-cards__card fds-layout-grid__cell">
            <div className="fmc-cards__media-area ">
              <img className="fmc-cards__image" src={reversing} alt="F-150 and trailer" />
            </div>
            <div className="fmc-cards__content">
              <p className="fmc-cards__body">Real world towing and backing up station on a closed-course.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="fmc-ma-5 fmc-divider--bottom">
        <a id="signup"></a>
        <h3 className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-3">Ready to tow like a pro?</h3>
        <p className="fds-color__text--tertiary fmc-mb-3">Limited spots available. Secure your spot today!</p>

        <div className="ford_towing_cards fmc-cards fds-layout-grid__inner fmc-mb-3">
          {/* <div className="fmc-cards__card fmc-ma-3 fds-layout-grid__cell--span-4">
            <div className="fmc-cards__content">
              <h2 className="fmc-cards__title">Raleigh, NC</h2>
              <h3 className="fmc-cards__subtitle">Raleigh RV Show</h3>
              <p className="fmc-cards__body">August 23, 24, or 25, 2024</p>
              <div className="fmc-cards__footer">
                <a href="/Raleigh">
                  <button className="fmc-button" aria-label="Register for Raleigh">
                    Register
                    <span className="fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"></span>
                  </button>
                </a>
              </div>
            </div>
          </div> */}
          <div className="fmc-cards__card fmc-ma-3 fds-layout-grid__cell--span-4">
            <div className="fmc-cards__content">
              <h2 className="fmc-cards__title">Dallas/Ft. Worth, TX</h2>
              <h3 className="fmc-cards__subtitle">Texas Motor Speedway</h3>
              <p className="fmc-cards__body">[ CANCELLED ]</p>
              {/* <p className="fmc-cards__body">September 5, 6, 7, or 8, 2024</p> */}
              <div className="fmc-cards__footer">
                {/* <a href="/DallasFtWorth"> */}
                <button className="fmc-button" aria-label="Register for Dallas/Ft. Worth" disabled={true}>
                  Register
                  <span className="fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"></span>
                </button>
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fmc-ma-5 fmc-divider--bottom">
        <h3 className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-3">What our graduates are saying:</h3>

        <p className="fds-color__text--tertiary fmc-mb-3">
          "We're getting to the age where we can retire and get an RV and go do those things and this'll give us the
          confidence to be able to do that safely."
          <br />- Cathy K.
        </p>

        <p className="fds-color__text--tertiary fmc-mb-3">
          "It way exceeded my expectations. It was great, great to be able to try this stuff out and figure out how
          stuff works and have the people that are experienced to go through it with you so you weren't trying to figure
          it out on your own."
          <br />- Steve B.
        </p>

        <p className="fds-color__text--tertiary fmc-mb-3">
          "I took advantage of the opportunity to have somebody like that [CDL trainer] here in order to answer all the
          questions that I've had on my mind for some time."
          <br />- Melissa T.
        </p>
      </section>

      <section className="fmc-ma-5 fmc-divider--bottom">
        <h3 className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-3">Meet our instructors:</h3>

        <div className="ford_towing_cards fmc-cards fds-layout-grid__inner fmc-mb-3">
          <div className="fmc-cards__card fmc-ma-3 fds-layout-grid__cell--span-4">
            <div className="fmc-cards__content">
              <div className="fmc-cards__media-area ">
                <img className="fmc-cards__image" src={maurice} alt="Maurice Mendes" />
              </div>
              <h2 className="fmc-cards__title">Maurice Mendes</h2>
              <p className="fmc-cards__body">
                Meet Maurice Mendes, a commercially licensed driver for 10+ years in the entertainment and marketing
                industries who...
              </p>
              <div className="fmc-cards__footer">
                <FMCPopover popup-tip close-button>
                  <button
                    slot="trigger"
                    className="fmc-button"
                    aria-label="Learn More"
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'instructor',
                        content_id: 'Maurice',
                      });
                    }}
                  >
                    Learn More
                  </button>
                  <h3 slot="heading" className="fmc-type--heading6">
                    Maurice Mendes
                  </h3>
                  <div slot="body">
                    Meet Maurice Mendes, a commercially licensed driver for 10+ years in the entertainment and marketing
                    industries who enjoys sharing his towing know-how. Maurice has extensive experience hauling boats,
                    campers, and even 40-ft.-long trailers cross-country. As an onsite technician, event coordinator,
                    stagehand, and tour liaison, he's also encountered a variety of uniquely demanding cargo towing
                    situations. A recent assignment involved towing an Airstream trailer up a ski slope for a
                    mountaintop event. Maurice's No. 1 towing tip is, "Never underestimate the importance of being alert
                    and aware of your surroundings when towing."
                  </div>
                </FMCPopover>
              </div>
            </div>
          </div>
          <div className="fmc-cards__card fmc-ma-3 fds-layout-grid__cell--span-4">
            <div className="fmc-cards__content">
              <div className="fmc-cards__media-area ">
                <img className="fmc-cards__image" src={darren_cindy} alt="Darren & Cindy Richardson" />
              </div>
              <h2 className="fmc-cards__title">Darren & Cindy Richardson</h2>
              <p className="fmc-cards__body">
                Meet Darren and Cindy Richardson, owners of a successful commercial trucking business with 40+ years of
                combined towing experience...
              </p>
              <div className="fmc-cards__footer">
                <FMCPopover popup-tip close-button>
                  <button
                    slot="trigger"
                    className="fmc-button"
                    aria-label="Learn More"
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'instructor',
                        content_id: 'DarrenCindy',
                      });
                    }}
                  >
                    Learn More
                  </button>
                  <h3 slot="heading" className="fmc-type--heading6">
                    Darren & Cindy Richardson
                  </h3>
                  <div slot="body" className="fmc-mt-3">
                    Meet Darren and Cindy Richardson, owners of a successful commercial trucking business with 40+ years
                    of combined towing experience. In their line of work, they train drivers regularly while also
                    handling a range of diverse, exciting sports and entertainment event towing assignments. These
                    include pulling sleighs using Ford F-150 ® trucks at the Super Bowl in Indianapolis and transporting
                    an F-16 fighter jet for the Air Force display at different NASCAR races and festivals. Darren and
                    Cindy are experts in what's known as "bumper towing", which uses a bumper pull and is the most
                    common type of hitch for hauling many types of trailers and loads behind a vehicle.
                  </div>
                </FMCPopover>
              </div>
            </div>
          </div>
          <div className="fmc-cards__card fmc-ma-3 fds-layout-grid__cell--span-4">
            <div className="fmc-cards__content">
              <div className="fmc-cards__media-area ">
                <img className="fmc-cards__image" src={carolina} alt="Carolina Paredes" />
              </div>
              <h2 className="fmc-cards__title">Carolina Paredes</h2>
              <p className="fmc-cards__body">
                Meet Carolina Paredes, a power sports enthusiast and Ford
                <span style={{ textWrap: 'nowrap' }}> F-150 </span> truck ambassador who owns her own branding and
                promotional company...
              </p>
              <div className="fmc-cards__footer">
                <FMCPopover popup-tip close-button>
                  <button
                    slot="trigger"
                    className="fmc-button"
                    aria-label="Learn More"
                    onClick={() => {
                      logEvent(analytics, 'select_content', {
                        content_type: 'instructor',
                        content_id: 'Carolina',
                      });
                    }}
                  >
                    Learn More
                  </button>
                  <h3 slot="heading" className="fmc-type--heading6">
                    Carolina Paredes
                  </h3>
                  <div slot="body">
                    Meet Carolina Paredes, a power sports enthusiast and Ford
                    <span style={{ textWrap: 'nowrap' }}> F-150 </span> truck ambassador who owns her own branding and
                    promotional company around experiential marketing and event production. An accomplished welder and
                    custom builder, Carolina works on an array of custom-built vehicles like motorcycles, vintage
                    trucks, and muscle cars while sharing inspiration with her 46K+ @madebycarolina Instagram followers.
                    She also appeared on Season 5 of CBS's reality competition show, Tough as Nails. Knowing how to
                    properly tow her equipment enables her business, lifestyle, and passion for helping women get into
                    power sports. Carolina is Spanish-bilingual and based out of Texas but is constantly on the road,
                    towing her own bikes on a trailer so she can ride them at whichever location she's headed next.
                  </div>
                </FMCPopover>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Main;
