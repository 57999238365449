import { initializeApp } from 'firebase/app';
import type { FirebaseOptions } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyC7Y6UtH27Ih7hFMgiTaVOsEuFI3KPMIPg',
  authDomain: 'ford-towing-bootcamp.firebaseapp.com',
  projectId: 'ford-towing-bootcamp',
  storageBucket: 'ford-towing-bootcamp.appspot.com',
  messagingSenderId: '756797076545',
  appId: '1:756797076545:web:3aad0468d4bb4f0d9982c0',
  measurementId: 'G-7S9CSQD6NM',
};

const app = initializeApp(firebaseConfig);

export default app;
