import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import app from '@/services/firebase';
import auth from '@/services/auth';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { Model, slk } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';

import 'survey-analytics/survey.analytics.min.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';

import cartJSONRaw from '../BootcampForm.json';
import preSurveyJSONRaw from '../../pre/PreSurvey.json';
import postSurveyJSONRaw from '../../post/PostSurvey.json';

slk('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5');

function DashboardScreen() {
  const navigate = useNavigate();
  const [whichData, setWhichData] = useState<'cart' | 'preSurvey' | 'postSurvey' | 'combinedSurveys'>('cart');

  const [user, userLoading, userError] = useAuthState(auth);
  const [thisSurvey, setThisSurvey] = useState<Model>();
  const [allAnswers, setAllAnswers] = useState<any[]>();
  const [surveyDataTable, setSurveyDataTable] = useState<Tabulator>();

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  const db = getFirestore(app, import.meta.env.VITE_DB_NAME);

  useEffect(() => {
    if (userLoading) return;

    if (!user) {
      navigate('./login');
    }
  }, [userLoading]);

  useEffect(() => {
    if (whichData === 'cart') {
      const surveyJSON = JSON.parse(JSON.stringify(cartJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'cartDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'carts');
      const q = query(answersRef, where('eventID', '==', 'raleigh'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'preSurvey') {
      const surveyJSON = JSON.parse(JSON.stringify(preSurveyJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const q = query(answersRef, where('eventID', '==', 'raleigh'), where('surveyType', '==', 'preTD'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'postSurvey') {
      const surveyJSON = JSON.parse(JSON.stringify(postSurveyJSONRaw));
      // add a couple fields to the survey json
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'id',
          visible: false,
        },
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const q = query(answersRef, where('eventID', '==', 'raleigh'), where('surveyType', '==', 'postTD'));
      getDocs(q).then(querySnapshot => {
        const answers: any[] = [];

        querySnapshot.forEach(doc => {
          const data = doc.data();
          answers.push({
            id: doc.id,
            ...data,
            displayName: data.name,
          });
        });

        setAllAnswers(answers);
      });
    }
    if (whichData === 'combinedSurveys') {
      const preSurveyJSON = JSON.parse(JSON.stringify(preSurveyJSONRaw));
      const postSurveyJSON = JSON.parse(JSON.stringify(postSurveyJSONRaw));

      let surveyJSON = preSurveyJSON;

      // Add all pages from postSurveyJSON to preSurveyJSON
      surveyJSON.pages.push(...postSurveyJSON.pages);

      // Add fields to the survey JSON
      surveyJSON.pages[0].elements.unshift(
        {
          type: 'text',
          name: 'attendeeID',
          visible: false,
        },
        {
          type: 'text',
          name: 'surveyDate',
          inputType: 'datetime-local',
          visible: false,
        },
      );

      const survey = new Model(surveyJSON);
      setThisSurvey(survey);

      // get the answers
      const answersRef = collection(db, 'surveys');
      const pre = query(answersRef, where('eventID', '==', 'raleigh'), where('surveyType', '==', 'preTD'));
      const post = query(answersRef, where('eventID', '==', 'raleigh'), where('surveyType', '==', 'postTD'));

      getDocs(pre).then(querySnapshot => {
        const answersCombined: any[] = [];
        const answersPre: any[] = [];
        const answersPost: any[] = [];

        console.log('preSurveys', querySnapshot.docs.length);
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          answersPre.push({
            ...data,
            displayName: data.name,
          });
        }

        getDocs(post).then(querySnapshot => {
          console.log('postSurveys', querySnapshot.docs.length);
          for (const doc of querySnapshot.docs) {
            const data = doc.data();
            answersPost.push(data);
          }

          // combine pre & post
          for (const preSurvey of answersPre) {
            const postSurvey = answersPost.find(post => post.attendeeID === preSurvey.attendeeID);
            if (postSurvey) {
              // combine the two
              answersCombined.push({
                ...preSurvey,
                ...postSurvey,
              });
            } else {
              answersCombined.push(preSurvey);
            }
          }
          setAllAnswers(answersCombined);
        });
      });
    }
  }, [whichData]);

  useEffect(() => {
    if (!thisSurvey || !allAnswers) return;

    const surveyDataTable = new Tabulator(thisSurvey, allAnswers, {
      downloadButtons: ['xlsx', 'csv', 'pdf'],
      useNamesAsTitles: true,
    });

    setSurveyDataTable(surveyDataTable);
  }, [thisSurvey, allAnswers]);

  useEffect(() => {
    if (!surveyDataTable) return;

    surveyDataTable.render('surveyDataTable');
    return () => {
      document.getElementById('surveyDataTable')!.innerHTML = '';
    };
  }, [surveyDataTable]);

  return (
    <div>
      <div className="fmc-mb-4">
        <ButtonGroup>
          <Button themeColor={whichData === 'cart' ? 'tertiary' : 'base'} onClick={() => setWhichData('cart')}>
            Cart
          </Button>
          <Button
            themeColor={whichData === 'preSurvey' ? 'tertiary' : 'base'}
            onClick={() => setWhichData('preSurvey')}
          >
            Pre Survey
          </Button>
          <Button
            themeColor={whichData === 'postSurvey' ? 'tertiary' : 'base'}
            onClick={() => setWhichData('postSurvey')}
          >
            Post Survey
          </Button>
          {/* Add the new button */}
          <Button
            themeColor={whichData === 'combinedSurveys' ? 'tertiary' : 'base'}
            onClick={() => setWhichData('combinedSurveys')}
          >
            Combined Surveys
          </Button>
        </ButtonGroup>
      </div>
      <h1>(Responders: {allAnswers?.length})</h1>

      <div id="surveyDataTable" />
    </div>
  );
}

export default DashboardScreen;
