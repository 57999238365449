import React from 'react';
import { Outlet } from 'react-router-dom';
import FordFooter from 'react-ford-ccpa-banner';
import { slide as Menu } from 'react-burger-menu';

import logo from '@/assets/images/logo/ford_logo.png';

const Layout = () => {
  return (
    <div className="fds-layout-grid ford_towing_root">
      <div className="fds-layout-grid__inner ford_towing_header">
        <div className="fds-hide--xs fds-align--left fds-va--middle fds-layout-grid__cell--span-5-sm fds-layout-grid__cell--span-8-md fds-layout-grid__cell--span-9-lg fds-layout-grid__cell--span-10-xl">
          <a href="/">
            <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
              About
            </button>
          </a>
          <a href="/contact">
            <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
              Contact Us
            </button>
          </a>
          <a href="/faq">
            <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
              FAQ
            </button>
          </a>
        </div>
        <div className="fds-show--xs fds-align--left fds-va--middle fds-layout-grid__cell--span-2-xs">
          <Menu>
            <a href="/">
              <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
                About
              </button>
            </a>
            <a href="/contact">
              <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
                Contact Us
              </button>
            </a>
            <a href="/faq">
              <button className="fmc-text-button fmc-mx-3 fmc-pa-1" style={{ fontWeight: 600 }}>
                FAQ
              </button>
            </a>
          </Menu>
        </div>
        <div className="fds-align--right fds-va--middle fds-layout-grid__cell--span-2-xs fds-layout-grid__cell--span-3-sm fds-layout-grid__cell--span-4-md fds-layout-grid__cell--span-3-lg fds-layout-grid__cell--span-2-xl">
          <img className="ford_banner_logo" src={logo} alt="Ford Logo" />
        </div>
      </div>
      <div className="ford_towing_content">
        <Outlet />
      </div>
      <div className="fds-layout-grid__inner fmc-mt-3">
        <div className="fds-layout-grid__cell--span-12">
          <FordFooter
            oneTrustEnabled={true}
            team={'ford'}
            imgStyle={{ paddingLeft: '30px' }}
            otherLinks={[{ id: 'programTerms', text: 'Program Terms & Conditions', url: '/terms' }]}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
