import React from 'react';
import { RendererFactory, Serializer } from 'survey-core';
import { ReactQuestionFactory, SurveyQuestionRadiogroup } from 'survey-react-ui';

import style from './_index.module.scss';

export default class RadioGroupRowQuestion extends SurveyQuestionRadiogroup {
  constructor(props: any) {
    super(props);

    (this as any).renderItem = (key: string, item: any, value: any, cssClasses: any, index?: string) => {
      /*
                key: key,
                question: this.question,
                cssClasses: cssClasses,
                isDisplayMode: this.isDisplayMode,
                item: item,
                textStyle: this.textStyle,
                index: index,
                isFirst: isFirst,
            */
      const inputId = `input_${this.question.name}_${item.id}`;
      const optionType = this.question.getType() === 'radiogroup' ? 'radio' : 'checkbox';

      let isChecked = this.question.isItemSelected(item);

      const handleOnChange = (event: any) => {
        this.question.clickItemHandler(item);
      };

      let itemClasses = [style.radio_box];

      if (this.question.buttonSize === 'small') {
        itemClasses = [style.radio_box_small];
      } else if (this.question.buttonSize === 'large') {
        itemClasses = [style.radio_box_large];
      }

      if (isChecked) {
        itemClasses.push(style.radio_box_active);
      }

      return (
        <div
          role="presentation"
          className={itemClasses.join(' ')}
          onClick={e => {
            const radio = document.getElementById(this.question.getItemId(item));
            if (radio && e.target !== radio) {
              radio.click();
            }
          }}
          key={key}
          id={inputId}
        >
          <label htmlFor={inputId}>
            {item.title}
            <input
              role="option"
              id={this.question.getItemId(item)}
              disabled={!this.question.getItemEnabled(item)}
              name={this.question.name + key}
              type={optionType}
              value={item.value}
              checked={isChecked}
              onChange={handleOnChange}
              onClick={e => e.stopPropagation()} // prevent event from bubbling up to the div
            />
          </label>
        </div>
      );
    };
  }

  getBody(cssClasses: any) {
    return <div className={style.radio_group_row}>{this.getItems(cssClasses, this.question.bodyItems)}</div>;
  }
}
