import React, { useEffect } from 'react';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { Survey } from 'survey-react-ui';

import * as Sentry from '@sentry/react';

import { PaymentIntent, Stripe, StripeEmbeddedCheckoutOptions, loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import { analytics, logEvent } from '@/firebase';

// import CheckoutForm from './CheckoutForm';

import logo from '@/assets/images/logo/built-ford-tough.png';
import 'survey-core/defaultV2.min.css';

import { themeJSON } from '@/assets/survey/FordTheme';
import baseSurvey from './BootcampForm.json';

import { FunctionFactory } from 'survey-core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { initBookeoQuestion } from './BookeoQuestion';

initBookeoQuestion();

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');
let stripe: Stripe | null;
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PK);
stripePromise.then(ret => {
  stripe = ret;
});

const EVENT_ID = 'raleigh';

function validateEmail(this: any, [questionName]: string[]) {
  console.log('[validateEmail]', arguments);
  if (!questionName) {
    this.returnResult(true);
    return;
  }

  const email = this.survey.getValue(questionName);

  if (!email) {
    this.returnResult(true);
    return;
  }

  fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}validateEmail-e2jtv773lq-uc.a.run.app`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  })
    .then(response => {
      response.json().then(res => {
        const { results } = res;

        let valid = true;

        // bad emails are rejected
        if (results?.valid === false) {
          valid = false;
        }

        // disposable email services are rejected
        if (results?.is_disposable === true) {
          valid = false;
        }

        // reject delivery_confidence below 20
        if (results?.delivery_confidence < 20) {
          valid = false;
        }

        // typos are rejected with correction
        if (results.did_you_mean) {
          valid = false;
        }

        if (!valid) {
          this.question.errors = [];
        }

        this.returnResult(valid);
      });
    })
    .catch(err => {
      alert(err);
    });
}

FunctionFactory.Instance.register('validateEmail', validateEmail, true);

const customCSS = {
  // bodyNavigationButton: 'fmc-button',
  // sd-text fmc-input
  text: {
    root: 'fmc-input',
    onError: 'fmc-input fmc-error',
  },
  checkbox: {
    root: 'fmc-check',
    item: '',
  },
  radiogroup: {
    root: 'fmc-check',
    onError: 'fmc-input fmc-error',
  },
};

const Main = () => {
  const [showCheckout, setShowCheckout] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState();
  const [transactionID, setTransactionID] = React.useState();

  // const appearance: Appearance = {
  //   theme: 'flat',
  //   variables: {
  //     fontFamily: "'FordMotion', Arial, Helvetica, sans-serif",
  //   },
  // };
  const options: StripeEmbeddedCheckoutOptions = {
    clientSecret: clientSecret || '',
    onComplete: () => {
      console.log('StripeEmbeddedCheckoutOptions onComplete');
      logEvent(analytics, 'purchase', {
        transaction_id: transactionID,
        currency: 'USD',
        value: survey.getValue('priceTotal'), // Total Revenue
      });
    },
    // appearance,
  };

  baseSurvey.title = 'Ford Towing Bootcamp Raleigh RV Show Registration';

  const survey = new Model(baseSurvey);
  survey.applyTheme(themeJSON);
  survey.css = customCSS;

  useEffect(() => {
    const scrollingElement = document.scrollingElement || document.body;
    if (clientSecret) {
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
      setTimeout(() => {
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      }, 3000);
    }
  }, [clientSecret]);

  survey.onPanelVisibleChanged.add((sender, options) => {
    if (!options.visible) {
      var questions = options.panel.questions;
      for (var i = 0; i < questions.length; i++) {
        var question = questions[i];
        sender.setValue(question.name, undefined);
      }
    }
  });

  survey.onComplete.add((sender, options) => {
    sender.setValue('eventID', EVENT_ID);
    sender.setValue('cartDate', new Date());
    console.log('onComplete', sender.data);

    const cart = {
      ...sender.data,
      bookeo_booking: {
        ...sender.getValue('bookeo_booking'),
        bookeoKey: sender.getQuestionByName('bookeo_booking').getPropertyValue('bookeoKey'),
        customFieldId: sender.getQuestionByName('bookeo_booking').getPropertyValue('customFieldId'),
        seats: sender.getQuestionByName('bookeo_booking').getPropertyValue('seats'),
        productId: sender.getQuestionByName('bookeo_booking').getPropertyValue('productId'),
        eventId: sender.getQuestionByName('bookeo_booking').getPropertyValue('eventId'),
      },
    };

    logEvent(analytics, 'begin_checkout', {
      currency: 'USD',
      value: sender.getValue('priceTotal'), // Total Revenue
    });

    // save data to firestore carts collection
    setShowCheckout(true);
    fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveCart-e2jtv773lq-uc.a.run.app`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: cart,
      }),
    })
      .then(response => {
        response.json().then(data => {
          setTransactionID(data.cartID);
          const thisCartID = data.cartID;

          options.showDataSavingSuccess();

          fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}createPaymentIntent-e2jtv773lq-uc.a.run.app`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              cartID: thisCartID,
              data: sender.data,
            }),
          }).then(response => {
            response.json().then(data => {
              setClientSecret(data.clientSecret);
            });
          });
        });
      })
      .catch(err => {
        console.error(err);
        Sentry.captureException(err, {
          tags: {
            page: 'Raleigh',
            location: 'surveyOnComplete',
            type: 'Stripe Payment Intent Error',
          },
        });
      });
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log('retrievePaymentIntent', paymentIntent?.status);

      switch (paymentIntent?.status) {
        case 'succeeded':
          // setMessage('Payment succeeded!');
          onPaymentComplete(paymentIntent);
          break;
        case 'processing':
          // setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          // setMessage('Your payment was not successful, please try again.');
          break;
        default:
          // setMessage('');
          // setIsLoading(false);
          break;
      }
    });
  }, [stripe]);

  const onPaymentComplete = (paymentIntent: PaymentIntent) => {
    console.log('onPaymentComplete', paymentIntent);
    setShowCheckout(false);

    const bookeoData = {
      bookeoKey: survey.getQuestionByName('bookeo_booking').getPropertyValue('bookeoKey'),
      customFieldId: survey.getQuestionByName('bookeo_booking').getPropertyValue('customFieldId'),
      seats: survey.getQuestionByName('bookeo_booking').getPropertyValue('seats'),
      productId: survey.getQuestionByName('bookeo_booking').getPropertyValue('productId'),
      previousHoldId: survey.getValue('bookeo_booking').previousHoldId,
      eventId: survey.getValue('bookeo_booking').eventId,
      firstName: survey.getValue('firstName'),
      lastName: survey.getValue('lastName'),
      emailAddress: survey.getValue('email'),
      phone: survey.getValue('phone'),
      type: 'mobile',
      customData: JSON.stringify({}),
    };

    fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}makeBookeoBooking-e2jtv773lq-uc.a.run.app`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bookeoData),
    })
      .then(response => {
        response.json().then(data => {
          if (!response.ok) {
            console.error('Bookeo API Booking Error', data);
            survey.complete();
          }

          const val = survey.getValue('bookeo_booking');

          val.bookingNumber = data.bookingNumber;
          survey.setValue('bookeo_booking', val);
          survey.complete();

          console.log('all values', survey.getAllValues());
        });
      })
      .catch(err => {
        console.error(err);
        Sentry.captureException(err, {
          tags: {
            page: 'Raleigh',
            location: 'onPaymentComplete',
            type: 'Bookeo API Booking Error',
          },
        });
        survey.complete();

        console.log('all values', survey.getAllValues());
      });
  };

  return (
    <>
      <div className="fds-layout-grid__inner">
        <div className="fds-layout-grid__cell--span-12">
          <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
            <div className="fmc-billboard__image event_billboard_background"></div>
            <div className="fmc-billboard__content towing_billboard_content">
              <img className="towing_billboard_logo" src={logo} alt="Built Ford Tough" />
            </div>
          </div>
          <span className="fmc-weight--light fmc-type--content2 fds-color__text--gray3">
            Towing capability varies based on cargo, vehicle configuration, accessories, and number and weight of
            passengers. Max towing often requires optional equipment at an extra charge. Doorjamb is the source of what
            an actual vehicle can tow.
          </span>
        </div>
      </div>
      <section className="fmc-ma-5 fmc-divider--bottom">
        <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
          The Ford Towing Bootcamp is coming to the Raleigh, NC RV Show!
        </h1>
        <h2 className="fds-color__text--tertiary fmc-type--heading5 fmc-mb-6 fmc-mb-5-md">
          Join us at one of our four hour training sessions.
        </h2>
        <p className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-1">Event Details:</p>
        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <li>
            <strong>Dates Available:</strong> August 23, 24, and 25, 2024
          </li>
          <li>
            <strong>Times:</strong> Four sessions available per day: 8:00 a.m., 10:00 a.m., 12:30 p.m., 2:30 p.m.
          </li>
          <li>
            <strong>Location:</strong> North Carolina State Fairgrounds, Raleigh, NC
          </li>
          <li>
            <strong>Address:</strong> 4285 Trinity Road, Raleigh, NC 27607
          </li>
        </ul>
        <p className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-1">What to Expect:</p>
        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <li>Includes admission to the NC RV Show</li>
          <li>Towing 101 class covering the basics you need to know before towing</li>
          <li>
            Behind-the-wheel training with industry professionals that includes:
            <ul className="fds-color__text--tertiary fmc-list">
              <li>Hitching and unhitching</li>
              <li>Backing up straight</li>
              <li>Backing up with a 90-degree turn</li>
              <li>Trailer reverse guidance</li>
              <li>Towing on a road course</li>
              <li>Narrow road navigation</li>
              <li>Slowing down/stopping</li>
            </ul>
          </li>
          <li>Expert towing demonstrations</li>
          <li>Hands-on experience with the Ford towing technology, equipment and techniques</li>
          <li>Q&A sessions with experienced instructors that can answer your towing questions</li>
        </ul>
        <p className="fds-color__text--tertiary fmc-type--heading6 fmc-mb-1">Registration:</p>
        <p className="fds-color__text--tertiary fmc-mb-6">Secure your spot today! Limited spaces available.</p>
        Registration Fee:
        <ul className="fds-color__text--tertiary fmc-list fmc-mb-6">
          <li>Program Cost (1 Participant): $495</li>
          <li>Add up to two (2) driving guests: $395 per driving guest</li>
          <li>
            Add up one (1) non-driving guest per driving participant in your reservation:
            <ul className="fds-color__text--tertiary fmc-list">
              <li>18 years of age or older: $25</li>
              <li>10 - 17 years of age: No additional cost</li>
            </ul>
          </li>
        </ul>
        <p className="fds-color__text--tertiary fmc-mb-6">
          To register please fill out the form below. We can't wait to see you at the Ford Towing Bootcamp at the
          Raleigh NC RV Show!
        </p>
      </section>

      {!showCheckout ? (
        <Survey model={survey}></Survey>
      ) : clientSecret ? (
        <EmbeddedCheckoutProvider options={options} stripe={stripePromise}>
          <EmbeddedCheckout />
          {/* <CheckoutForm onPaymentComplete={onPaymentComplete} clientSecret={clientSecret} /> */}
        </EmbeddedCheckoutProvider>
      ) : (
        <div className="fmc-activity-circle">
          <div className="fmc-activity-circle__progress"></div>
        </div>
      )}

      <p className="fds-color__text--gray3 fds-style--italic fmc-ma-6">
        Please keep in mind that towing capability varies based on cargo, vehicle configuration, accessories, and number
        and weight of passengers. Max tow often requires optional equipment at an extra charge. Doorjamb is the source
        of what an actual vehicle can tow.
      </p>
    </>
  );
};

export default Main;
