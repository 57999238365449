import React from 'react';

const Main = () => {
  return (
    <>
      <section className="fmc-ma-5">
        <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Contact Us</h1>

        <p className="fds-color__text--tertiary fmc-type--subtitle1 fmc-mt-3">
          <strong>Have a question or need help? </strong>
        </p>
        <p className="fds-color__text--tertiary">Call: (888) 367-3192</p>
        <p className="fds-color__text--tertiary">
          Email: <a href="mailto:info@fordtowingbootcamp.com">info@fordtowingbootcamp.com</a>
        </p>
      </section>
    </>
  );
};

export default Main;
