/// <reference types="@types/google.maps" />

import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';

import { ReactQuestionFactory } from 'survey-react-ui';

import { Survey } from 'survey-react-ui';
import showdown from 'showdown';

import * as Sentry from '@sentry/react';

import 'survey-core/defaultV2.min.css';
import '@/assets/css/survey-override.css';

import { themeJSON } from '@/assets/survey/FordTheme';
import baseSurvey from './PostSurvey.json';
import { useSearchParams } from 'react-router-dom';
import { createElement, useEffect, useState } from 'react';
import { Attendee } from '@/types/attendee';

SurveyCore.Serializer.addProperty('itemvalue', 'image');
SurveyCore.Serializer.addProperty('radiogroup', 'buttonSize');
SurveyCore.Serializer.addProperty('itemvalue', 'image');

import CheckboxVOIQuestion from '@/assets/survey/surveysjs_renderers/CheckboxVOI';

ReactQuestionFactory.Instance.registerQuestion('sv-checkbox-voi', props => {
  return createElement(CheckboxVOIQuestion, props);
});

SurveyCore.RendererFactory.Instance.registerRenderer('checkbox', 'voi', 'sv-checkbox-voi');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RadioGroupRowQuestion from '@/assets/survey/surveysjs_renderers/RadioButtonButton';

ReactQuestionFactory.Instance.registerQuestion('sv-radio-button-group', props => {
  return createElement(RadioGroupRowQuestion, props);
});

SurveyCore.RendererFactory.Instance.registerRenderer('radiogroup', 'radiobuttongroup', 'sv-radio-button-group');

SurveyCore.setLicenseKey('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wMS0wNA==');

const customCSS = {
  // bodyNavigationButton: 'fmc-button',
  // sd-text fmc-input
  text: {
    root: 'fmc-input',
    onError: 'fmc-input fmc-error',
  },
  checkbox: {
    root: 'fmc-check',
  },
  radiogroup: {
    root: 'fmc-check',
    onError: 'fmc-input fmc-error',
  },
  completedBeforePage: '',
};

baseSurvey.title = 'Ford Towing Bootcamp Post-Survey';
const surveyModel = new Model(baseSurvey);
surveyModel.applyTheme(themeJSON);
surveyModel.css = customCSS;

const PostSurvey = () => {
  const [searchParams] = useSearchParams();
  const attendeeID = searchParams.get('attendeeID');

  const [attendee, setAttendee] = useState<Attendee>();
  const [attendeeLoading, setAttendeeLoading] = useState(true);

  useEffect(() => {
    if (attendeeID) {
      const fetchAttendee = async () => {
        try {
          const response = await fetch(
            `https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}getAttendee-e2jtv773lq-uc.a.run.app`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ attendeeID }),
            },
          );

          if (!response.ok) {
            console.error('Error fetching attendee:', response);
            setAttendeeLoading(false);
            throw new Error('Failed to fetch attendee');
          }

          const attendeeData = await response.json();
          console.log('attendeeData', attendeeData);

          surveyModel.title = `${baseSurvey.title}`;

          surveyModel.mergeData({
            surveyType: 'postTD',
            attendeeID,
            eventID: attendeeData.eventID,
            cartID: attendeeData.cartID,
            firstName: attendeeData.firstName,
            lastName: attendeeData.lastName,
            email: attendeeData.email,
            phone: attendeeData.phone,
          });

          setAttendee(attendeeData as Attendee);
          setAttendeeLoading(false);
        } catch (error) {
          console.error('Error fetching attendee:', error);
          setAttendeeLoading(false);
          throw error;
        }
      };

      fetchAttendee();
    }
  }, [attendeeID]);

  useEffect(() => {
    var converter = new showdown.Converter({
      openLinksInNewWindow: true,
    });
    surveyModel.onTextMarkdown.add(function (sender, options) {
      //convert the mardown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });

    surveyModel.onComplete.add((sender, options) => {
      sender.setValue('surveyDate', new Date());

      console.log('onComplete', sender.data);

      const data = {
        ...sender.data,
      };

      // logEvent(analytics, 'begin_checkout', {
      //   currency: 'USD',
      //   value: sender.getValue('priceTotal'), // Total Revenue
      // });

      // save data to firestore carts collection
      fetch(`https://${import.meta.env.VITE_FIREBASE_FUNCTION_PREFIX}saveSurvey-e2jtv773lq-uc.a.run.app`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          survey: data,
        }),
      })
        .then(() => {
          options.showDataSavingSuccess();
          const redirect = searchParams.get('redirect');
          if (redirect) {
            window.location.href = redirect;
          }
        })
        .catch(err => {
          console.error(err);
          Sentry.captureException(err, {
            tags: {
              page: 'PostDrive',
              location: 'surveyOnComplete',
              type: 'Survey Save Error',
            },
          });
        });
    });
  }, []);

  return (
    <>
      {attendee ? (
        attendee._used ? (
          <>
            <div className="fds-layout-grid__inner">
              <div className="fds-layout-grid__cell--span-12">
                <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                  <div className="fmc-billboard__image event_billboard_background"></div>
                </div>
              </div>
            </div>
            <section className="fmc-ma-5">
              <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">
                Post-Survey Already Completed
              </h1>
            </section>
          </>
        ) : (
          <Survey model={surveyModel} />
        )
      ) : !attendeeLoading ? (
        <>
          <div className="fds-layout-grid__inner">
            <div className="fds-layout-grid__cell--span-12">
              <div className="fmc-billboard towing_billboard fmc-billboard--justify-start fmc-billboard--align-start">
                <div className="fmc-billboard__image event_billboard_background"></div>
              </div>
            </div>
          </div>
          <section className="fmc-ma-5">
            <h1 className="fds-color__text--tertiary fmc-type--heading4 fmc-mb-6 fmc-mb-5-md">Invalid ID</h1>
          </section>
        </>
      ) : (
        <div>Loading Survey...</div>
      )}
    </>
  );
};

export default PostSurvey;
