import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, getDocs, getDoc, doc, Timestamp } from 'firebase/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import app from '@/services/firebase';
import auth from '@/services/auth';

import { Model, slk } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';

import 'survey-analytics/survey.analytics.min.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import moment from 'moment-timezone';

slk('NDBhNThlYzYtN2EwMy00ZTgxLWIyNGQtOGFkZWJkM2NlNjI3OzE9MjAyNS0wNy0xOSwyPTIwMjUtMDctMTksND0yMDI1LTA3LTE5');

const surveyJSON = JSON.stringify({
  pages: [
    {
      name: 'vml',
      elements: [
        {
          type: 'text',
          name: 'fordPassID',
          title: 'FordPass Rewards',
        },
        {
          type: 'text',
          name: 'registrantType',
          title: 'Registrant Type',
        },
        {
          type: 'text',
          name: 'firstName',
          title: 'First Name',
        },
        {
          type: 'text',
          name: 'lastName',
          title: 'Last Name',
        },
        {
          type: 'text',
          name: 'email',
          title: 'Email',
        },
        {
          type: 'text',
          name: 'amountSubtotal',
          title: 'Amount Subtotal',
        },
        {
          type: 'text',
          name: 'amountPaid',
          title: 'Amount Paid',
        },
        {
          type: 'text',
          name: 'eventID',
          title: 'Event',
        },
        {
          type: 'text',
          name: 'eventTime',
          title: 'Event Slot',
        },
        {
          type: 'text',
          name: 'eventDT',
          inputType: 'datetime-local',
          title: 'Event DateTime',
        },
        {
          type: 'text',
          name: 'drivingGuests',
          title: 'Driving Guests',
        },
        {
          type: 'text',
          name: '_checkedIn',
          inputType: 'datetime-local',
          title: 'Checked In',
        },
        {
          type: 'text',
          name: '_checkedOut',
          inputType: 'datetime-local',
          title: 'Checked Out',
        },
      ],
    },
  ],
});

function VMLDashboardScreen() {
  const navigate = useNavigate();

  const [user, userLoading, userError] = useAuthState(auth);

  const [thisSurvey, setThisSurvey] = useState<Model>();
  const [allAnswers, setAllAnswers] = useState<any[]>();
  const [surveyDataTable, setSurveyDataTable] = useState<Tabulator>();

  useEffect(() => {
    console.error(userError);
  }, [userError]);

  const db = getFirestore(app, import.meta.env.VITE_DB_NAME);

  useEffect(() => {
    if (userLoading) return;

    if (!user) {
      navigate('./login');
    }
  }, [userLoading]);

  useEffect(() => {
    const survey = new Model(surveyJSON);
    setThisSurvey(survey);
    console.log('Model set');

    const carts: { [key: string]: any } = {};

    // get the answers
    const attendeesRef = collection(db, 'attendees');
    const q = query(attendeesRef);

    getDocs(q).then(async querySnapshot => {
      console.log('Attendees fetched', querySnapshot.size);
      const answers: any[] = [];

      for (const docRef of querySnapshot.docs) {
        const thisAttendee = docRef.data();

        if (!carts[thisAttendee.cartID]) {
          const cartRef = doc(db, 'carts', thisAttendee.cartID);
          carts[thisAttendee.cartID] = (await getDoc(cartRef)).data();
        }

        const thisCart: { [key: string]: any } = carts[thisAttendee.cartID] as { [key: string]: any };
        const isPrimary = thisAttendee.type === 'primary';

        answers.push({
          fordPassID: isPrimary ? thisCart.fordPassID : '',
          registrantType: thisAttendee.type,
          firstName: thisAttendee.firstName,
          lastName: thisAttendee.lastName,
          email: thisAttendee.email,
          amountPaid: isPrimary
            ? (Number(thisCart.stripe.amount_total) / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : '',
          amountSubtotal: isPrimary
            ? (Number(thisCart.stripe.amount_subtotal) / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            : '',
          eventID: thisAttendee.eventID,
          eventTime: thisAttendee.eventTime,
          eventDT: moment(thisAttendee.eventTime, 'dddd MMM D, h:mm a').toDate(),
          drivingGuests: isPrimary ? thisCart.drivingGuests : '',
          _checkedIn: thisAttendee._checkedIn ? (thisAttendee._checkedIn as Timestamp).toDate() : '',
          _checkedOut: thisAttendee._checkedOut ? (thisAttendee._checkedOut as Timestamp).toDate() : '',
        });
      }

      setAllAnswers(answers.sort((a, b) => (a.eventDT > b.eventDT ? 1 : -1)));
    });
  }, []);

  useEffect(() => {
    if (!thisSurvey || !allAnswers) return;

    const surveyDataTable = new Tabulator(thisSurvey, allAnswers, {
      downloadButtons: ['xlsx', 'csv', 'pdf'],
      useNamesAsTitles: true,
    });

    setSurveyDataTable(surveyDataTable);
  }, [thisSurvey, allAnswers]);

  useEffect(() => {
    if (!surveyDataTable) return;

    surveyDataTable.render('surveyDataTable');
    return () => {
      document.getElementById('surveyDataTable')!.innerHTML = '';
    };
  }, [surveyDataTable]);

  return (
    <div>
      <div id="surveyDataTable" />
    </div>
  );
}

export default VMLDashboardScreen;
