import { RouteObject } from 'react-router-dom';
import Layout from '@/pages/layout';

import Main from '@/pages/main';
import Terms from '@/pages/terms';
import Contact from '@/pages/contact';
import FAQ from '@/pages/faq';

import Login from '@/pages/login';

import Raleigh from '@/pages/raleigh';
import RCheckIn from '@/pages/in/CheckIn';
import RCheckOut from '@/pages/out/CheckOut';
import RDashboard from '@/pages/raleigh/dashboard';
import DallasFtWorth from '@/pages/dallas_ftworth';
import PurchaseThanks from '@/pages/purchaseThanks';

import VMLDashboard from '@/pages/vml';

import Pre from '@/pages/pre';
import Post from '@/pages/post';

import NotFound from '@/pages/NotFound';

export default [
  {
    path: '/',
    element: <Layout />,
    children: [{ index: true, element: <Main /> }],
  },
  {
    path: '/contact',
    element: <Layout />,
    children: [{ index: true, element: <Contact /> }],
  },
  {
    path: '/faq',
    element: <Layout />,
    children: [{ index: true, element: <FAQ /> }],
  },
  {
    path: '/terms',
    element: <Layout />,
    children: [{ index: true, element: <Terms /> }],
  },
  // {
  //   path: '/DallasFtWorth',
  //   element: <Layout />,
  //   children: [{ index: true, element: <DallasFtWorth /> }],
  // },
  {
    path: '/Raleigh',
    element: <Layout />,
    children: [{ index: true, element: <Raleigh /> }],
  },
  {
    path: '/Raleigh/dashboard',
    element: <Layout />,
    children: [{ index: true, element: <RDashboard /> }],
  },
  {
    path: '/Raleigh/dashboard/login',
    element: <Layout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: '/Raleigh/in',
    element: <Layout />,
    children: [{ index: true, element: <RCheckIn /> }],
  },
  {
    path: '/Raleigh/in/login',
    element: <Layout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: '/Raleigh/out',
    element: <Layout />,
    children: [{ index: true, element: <RCheckOut /> }],
  },
  {
    path: '/Raleigh/out/login',
    element: <Layout />,
    children: [{ index: true, element: <Login /> }],
  },
  {
    path: '/purchaseThanks',
    element: <Layout />,
    children: [{ index: true, element: <PurchaseThanks /> }],
  },
  {
    path: '/pre',
    element: <Layout />,
    children: [{ index: true, element: <Pre /> }],
  },
  {
    path: '/post',
    element: <Layout />,
    children: [{ index: true, element: <Post /> }],
  },

  {
    path: '/vml',
    element: <Layout />,
    children: [{ index: true, element: <VMLDashboard /> }],
  },
  {
    path: '/vml/login',
    element: <Layout />,
    children: [{ index: true, element: <Login /> }],
  },

  {
    path: '/*',
    element: <NotFound />,
  },
] as RouteObject[];
