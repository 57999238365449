import { SurveyQuestionCheckbox } from 'survey-react-ui';

import style from './_index.module.scss';

export default class CheckboxVOIQuestion extends SurveyQuestionCheckbox {
  getBody(cssClasses: any) {
    return <div className={style.radio_group_voi}>{this.getItems(cssClasses, this.question.bodyItems)}</div>;
  }

  renderItem(key: string, item: any) {
    const imageURL = `https://cdn.latitudewebservices.com/vehicles/images/${item.image}`;
    const inputId = `input_${this.question.name}_${item.id}`;

    let isChecked = this.question.isItemSelected(item);

    const handleOnChange = (event: any) => {
      this.question.clickItemHandler(item, event.target.checked);
    };

    return (
      <div
        role="presentation"
        className={isChecked ? `${style.voi_option} ${style.voi_option_active}` : `${style.voi_option}`}
        onClick={e => {
          const checkbox = document.getElementById(`checkbox_${inputId}`);
          if (checkbox && e.target !== checkbox) {
            checkbox.click();
          }
        }}
        key={key}
        id={inputId}
      >
        <img alt={item.title} src={imageURL} className={style.vehicle_img} />
        <label htmlFor={inputId}>
          {item.title.replace('-', '‑')}
          <input
            className=""
            role="option"
            id={`checkbox_${inputId}`}
            disabled={!this.question.getItemEnabled(item)}
            name={this.question.name + key}
            type="checkbox"
            value={item.value}
            checked={isChecked}
            onChange={handleOnChange}
            onClick={e => e.stopPropagation()} // prevent event from bubbling up to the div
          />
        </label>
      </div>
    );
  }
}
